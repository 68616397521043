<template>
  <v-app>
    <Navigation :pageTitle="routeName" />
    <v-main>
      <!-- MAIN ROUTER VIEW -->
      <v-container fluid class="overflow-y-auto fill-height d-block" id="main">
        <!-- Ces tags sont importants car ils permettent de détecter le scrolling -->
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation";

export default {
  name: "app",
  components: { Navigation },
  computed: {
    routeName() {
      return this.$route.meta.name;
    },
  },
};
</script>

<style>
html,
body {
  height: 100%;
  overflow: hidden; /* Prevent out of page scrolling (better for UIs with vertical bars: eg. outlook.com) */
  background-color: rgb(39, 39, 39); /* Out of page (scrolling) color */
}
.v-main {
  background-color: var(--v-appback-base);
}
.v-application,
.v-main {
  height: 100%;
}
/* Hide scrollbar */
body::-webkit-scrollbar,
#main::-webkit-scrollbar {
  background: transparent; /* Webkit */
}
body,
#main {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>