<template>
  <div>
    <!-- TOP BAR -->
    <v-app-bar color="topnav" dense dark app clipped-left>
      <v-btn icon plain to="/">
        <v-icon> mdi-home </v-icon>
      </v-btn>
      <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon plain to="/logout">
        <v-icon> mdi-logout </v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
/*import {
  setDarkMode,
  storedDarkMode,
} from "@/utils/storage";*/

export default {
  name: "Navigation",
  data() {
    return {
      // CONTROL
      drawer: false,
      expandSearch: false,
      expand: false,
    };
  },
  props: {
    pageTitle: String,
  },
  computed: {
    miniScreen() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    authenticated() {
      return this.$store.getters.authenticated;
    },
    getName() {
      return this.$store.getters.getName;
    },
    getUserName() {
      return this.$store.getters.getUserName;
    },
  },
};
</script>

<style>
.nav-link {
  text-decoration: none;
}
</style>
