import Vue from 'vue';
import Vuex from 'vuex';

import localforage from 'localforage';

Vue.use(Vuex)

localforage.config({
    name: 'Cache Web'
});

/*
 * This class implements a storage that is super volatile (will update in Vue on change),
 * but it still restored during the session using sessionStorage.
 */

const globalStore = new Vuex.Store({
    state: {
        // User data: get init from session
        user: {
            name: undefined,
            username: undefined,
        },
    },
    mutations: {
        set(state, payload) {
            Vue.set(state, payload.key, payload.val);
            if (payload.store || payload.store == undefined) {
                localforage.setItem(payload.key, JSON.stringify(payload.val));
            }
        },
        resetUser(state) {
            state.user = {
                name: "",
                username: ""
            }
            localforage.removeItem("user");
        },

    },
    getters: {
        authenticated: (state) => {
            return Boolean(state.user) && Boolean(state.user.username);
        },
        getName: (state, getters) => {
            return getters.authenticated && state.user.name;
        },
        getUserName: (state, getters) => {
            return getters.authenticated && state.user.username;
        },
    },
    actions: {
        initialization(context) {
            // This function is called BEFORE Vue is even loaded. (main.js)
            return localforage.getItem("user").then(val => {
                context.commit('set', { key: "user", val: JSON.parse(val), store: false });
                return true;
            });
        }
    },
});

function setInitData(data) {
    setUser(data.user);
}
function setUser(data) {
    globalStore.commit('set', { key: 'user', val: data });
}
function clearLogin() {
    globalStore.commit('resetUser');
}


export {
    globalStore,
    setInitData,
    setUser,
    clearLogin,
}