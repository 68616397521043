import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

import { globalStore } from "@/utils/storage";
import router from './routes/routes';

import AxiosWrappers from "./plugins/axiosWrappers"
Vue.use(AxiosWrappers);

globalStore.dispatch('initialization').then(() => {
  new Vue({
    vuetify,
    router,
    store: globalStore,
    render: h => h(App)
  }).$mount('#app')
});